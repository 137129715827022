import React from 'react';
import BuyFormData from '../layout/trade/BuyFormData';


const BuyFormDataPage = () => {
  return (
      <div>
          <BuyFormData />
      </div>
  )
}

export default BuyFormDataPage