import React, { useState, useContext } from 'react';
import CryptoInput from './CryptoInput';
import FiatInput from './FiatInput';
// import { ArrowDownUp } from 'react-bootstrap-icons';
import { useRecoilState } from 'recoil';
import { isFiatOnTopAtom } from '../../../recoil/atoms/randomAtom';
import {
  cryptoAmountAtom,
  fiatAmountAtom,
  sellRateAtom, lpFeeAtom, lpFeeRateAtom, genericRateAtom, baseRateAtom
} from '../../../recoil/atoms/rateAtom';
import { useNavigate } from 'react-router-dom';
import { exchangeRateContext } from '../../../context/Context';

const SwapFormAggregation = () => {
  const { sellRate, setSellRate } = useContext(exchangeRateContext);
  const [cryptoAmount, setCryptoAmount] = useRecoilState(cryptoAmountAtom);
  const [fiatAmount, setFiatAmount] = useRecoilState(fiatAmountAtom);
  // const [sellRate] = useRecoilState(sellRateAtom);
  const exchangeRate = sellRate;
  const transactionFee = 1; // Transaction fee: 1 Crypto
  const [isFiatOnTop, setIsFiatOnTop] = useRecoilState(isFiatOnTopAtom);
  const [transactionType, setTransactionType] = useState('Sell');
  const [totalLpFee, setTotalLpFee] = useRecoilState(lpFeeAtom);
  const [lpFeeRate, setLpFeeRate] = useRecoilState(lpFeeRateAtom);
  const [genericRate, setGenericRate] = useRecoilState(genericRateAtom);
  const [baseRate, setBaseRate] = useRecoilState(baseRateAtom);


  const handleTransactionType = typeOfTrade => {
    setTransactionType(typeOfTrade);
  };


 
  

  
  const navigate = useNavigate();

  const handleToggleInputs = () => {
    setIsFiatOnTop(prevIsFiatOnTop => !prevIsFiatOnTop);

    // Navigate the user based on the updated isFiatOnTop state
    if (isFiatOnTop) {
      // navigate("/trade/buy");
      console.log('this is up,', transactionType);
      setIsFiatOnTop(false);
      handleTransactionType('Buy');
    } else {
      // navigate("/trade/sell");
      console.log('this is down,', transactionType);
      setIsFiatOnTop(true);
      handleTransactionType('Sell');
    }
  };

  // const handleToggleInputs = () => {
  //   setIsFiatOnTop((prevIsFiatOnTop) => !prevIsFiatOnTop);
  // };

  // const handleCryptoChange = event => {
  //   const amount = event.target.value;
  //   setCryptoAmount(amount);
  //   const cryptoAfterNetworkFee = Math.max(amount - transactionFee, 0); // Deduct transaction fee
  //   const equivalentFiat = cryptoAfterNetworkFee * exchangeRate; 
  //   setFiatAmount(equivalentFiat);
  // };

  // const handleCryptoChange = event => {
  //   const amount = event.target.value;
  //   setCryptoAmount(amount);
  
  //   const cryptoAfterNetworkFee = Math.max(amount - transactionFee, 0); // Deduct transaction fee
  
  //   if (transactionType === 'Sell') {
  //     const equivalentFiat = amount * exchangeRate;
  //     const totalSellFiatAfterLpFee = equivalentFiat  - totalLpFee;
  //     setFiatAmount(totalSellFiatAfterLpFee);
  //   } else if (transactionType === 'Buy') {
  //     const equivalentFiat = cryptoAfterNetworkFee * exchangeRate;
  //     const totalBuyFiatAfterLpFee = equivalentFiat - totalLpFee;
  //     setFiatAmount(totalBuyFiatAfterLpFee);
  //   }
  // };
  

  const handleCryptoChange = event => {
    const amount = parseFloat(event.target.value);
    setCryptoAmount(amount);
    const cryptoAfterNetworkFee = Math.max(amount - transactionFee, 0); 
    let equivalentFiat;
    if (transactionType === 'Sell') {
      const totalLpFee = lpFeeRate * amount;
      equivalentFiat = amount * sellRate;
      const totalSellFiatAfterLpFee = equivalentFiat - totalLpFee;
      setTotalLpFee(totalLpFee); 
      setFiatAmount(totalSellFiatAfterLpFee); 
    } else if (transactionType === 'Buy') {
      equivalentFiat = cryptoAfterNetworkFee * sellRate;
      const totalBuyFiatAfterLpFee = equivalentFiat - totalLpFee;
      setFiatAmount(totalBuyFiatAfterLpFee);
    }
  };

  




  // const handleCryptoChange = event => {
  //   const amount = event.target.value;
  //   setCryptoAmount(amount);
  //   if (transactionType === 'sell') {
  //     const sellFiatAfterLpFee = amount - totalLpFee;
  //   setCryptoAmount(sellFiatAfterLpFee);
  //   } else if (transactionType === 'buy') {
  //     const cryptoAfterNetworkFee = Math.max(amount - transactionFee, 0); // Deduct transaction fee
  //     const equivalentFiat = cryptoAfterNetworkFee * exchangeRate; 
  //     const buyFiatAfterLpFee = equivalentFiat - totalLpFee;
  //     setFiatAmount(buyFiatAfterLpFee);
  //   }
   
  // };

  // const handleCryptoChange = event => {
  //   const amount = event.target.value;
  //   setCryptoAmount(amount);
  
  //   // Calculate crypto amount after transaction fee
  //   const cryptoAfterFee = Math.max(amount - transactionFee, 0); 
  
  //   // Calculate equivalent fiat amount
  //   let equivalentFiat;
  
  //   // Check transaction type
  //   if (transactionType === 'buy') {
  //     // Deduct transaction fee from crypto amount and total LP fee from fiat amount
  //     equivalentFiat = (cryptoAfterFee * exchangeRate) - totalLpFee;
  //   } else if (transactionType === 'sell') {
  //     // Deduct only total LP fee from fiat amount
  //     equivalentFiat = amount * exchangeRate - totalLpFee;
  //   } else {
  //     // Handle other transaction types if needed
  //     // For simplicity, let's set equivalentFiat to 0 for unknown transaction types
  //     equivalentFiat = 0;
  //   }
  
  //   // Set equivalent fiat amount
  //   setFiatAmount(Math.max(equivalentFiat, 0));
  // };
  
  

  const handleFiatChange = event => {
    setFiatAmount(event.target.value);
    const equivalentCrypto = event.target.value / exchangeRate;
    // setCryptoAmount(equivalentCrypto);
  };

  

  

  return (
    <div className="exchange-form-container">
      <div className={`input-container ${isFiatOnTop ? 'top' : 'bottom'}`}>
        {isFiatOnTop ? (
          <div>
            <CryptoInput
              cryptoAmount={cryptoAmount}
              onCryptoChange={handleCryptoChange}
            />
            <FiatInput
              fiatAmount={fiatAmount}
              // onFiatChange={handleFiatChange}
            />
          </div>
        ) : (
          <div>
            <FiatInput
              fiatAmount={fiatAmount}
              onFiatChange={handleFiatChange}
            />
            <CryptoInput
              cryptoAmount={cryptoAmount}
              onCryptoChange={handleCryptoChange}
            />
          </div>
        )}
      </div>
      {/** <button onClick={handleToggleInputs} className="updown-button">
        <ArrowDownUp size={20} />
      </button> */}
    </div>
  );
};

export default SwapFormAggregation;
