import React from 'react';
import AfterSalesContact from '../layout/trade/AfterSalesContact';

const AfterSalesContactPage = () => {
  return (
      <div>
          <AfterSalesContact />
      </div>
  )
}

export default AfterSalesContactPage