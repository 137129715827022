import React from 'react';
import ChangeRate from '../layout/trade/ChangeRate';

const ChangeRateAccordionPage = () => {
  return (
    <div>
      <ChangeRate />
    </div>
  );
}

export default ChangeRateAccordionPage