
import React, { createContext, useState, useEffect } from 'react';

export const exchangeRateContext = createContext();

export const ExchangeRateProvider = ({ children }) => {
  const [sellRate, setSellRate] = useState(2001);

  const updateSellRate = (newSellRate) => {
    setSellRate(newSellRate);
  };

    useEffect(() => {
     setSellRate(localStorage.getItem('sellRate') || sellRate);
  }, [])

  return (
    <exchangeRateContext.Provider value={{ sellRate, setSellRate: updateSellRate }}>
     {/* <exchangeRateContext.Provider value={{ sellRate, updateSellRate}}> */}
      {children}
    </exchangeRateContext.Provider>
  );
};
