import React from 'react';
import SecurityQuestion from '../layout/trade/SecurityQuestion';

const SecurityQuestionPage = () => {
  return (
      <div>
        <SecurityQuestion />
      </div>
  )
}

export default SecurityQuestionPage