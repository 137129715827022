import React from 'react';
import BuyPayment from '../layout/trade/BuyPayment';

const BuyPaymentPage = () => {
  return (
      <div>
        <BuyPayment />
      </div>
  )
}

export default BuyPaymentPage