import React from 'react';
import SellFormData from "../layout/trade/SellFormData"

const SellFormDataPage = () => {
  return (
      <div>
        <SellFormData />
      </div>
  )
}

export default SellFormDataPage