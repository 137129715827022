import React, { useState } from 'react';
import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  IconButton,
  Button,
  VStack,
  HStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from '@chakra-ui/react';
import { MdEmail, MdOutlineEmail, MdPhoneInTalk } from 'react-icons/md';
import { BsPerson, BsCheckCircleFill, BsWhatsapp } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import TradeNavbar from './SwapNavbar';

export default function Contact() {
  //  const navigate = useNavigate();
  const [isUsername, setIsUsername] = useState('');
  const [isUserEmail, setIsUserEmail] = useState('');
  const [isUserPhone, setIsUserPhone] = useState('');
  const [isUserMessage, setIsUserMessage] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);

  //  const handleContactButtonClick = () => {
  //    navigate("/trade/sell/payment");
  //  };
  const email = 'hello@bullstand.com';
  const whatsappUrl =
    'https://wa.me/23408139909526?text=Hi%20BullStand,%20I%20Just%20Completed%20Payment.%20Waiting%20to%20get%20to%20funded';
    const telegramUrl = 'https://t.me/bullstand_exchange';

  console.log('isUsername : ', isUsername);
  console.log('isUserEmail : ', isUserEmail);
  console.log('isUserPhone : ', isUserPhone);
  console.log('isUserMessage : ', isUserMessage);

  const handleSubmit = async e => {
    e.preventDefault(); 

    const id = '1FAIpQLSdTeBsHjoTkKpVlVnZLSgA_SNJWR87XE0vM-yjg8akSAhKTUQ';
    const formUrl = `https://docs.google.com/forms/d/e/${id}/formResponse`;

    const formData = new URLSearchParams({
      'entry.89766816': isUsername,
      'entry.987368572': isUserEmail,
      'entry.1046938577': isUserPhone,
      'entry.159797155': isUserMessage,
    });

    try {
      const response = await fetch(formUrl, {
        method: 'POST',
        body: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (response.ok) {
        // handleSellButtonClick();
        setIsSuccessful(true);
        console.log('Form submitted successfully');
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      // handleSellButtonClick();
      setIsSuccessful(true);
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div>
      {isSuccessful ? (
        <div>
          <TradeNavbar />
          <Box textAlign="center" py={10} px={6} mt={16} bg="#e3eef3">
            <Flex justifyContent="center" alignItems="center">
              <BsCheckCircleFill size={'40px'} color={'#4CAF50'} />
            </Flex>
            <Heading as="h2" size="xl" mt={6} mb={2} color={'green.500'}>
              Thank You for contacting us
            </Heading>
            <Text color={'gray.500'}>
              We typically reply in less than an hour, if you want to excalate further, whatsapp or Telegram is faster.
            </Text>
            <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                          <HStack pl={360} spacing={3} alignItems="flex-start">
                            <a
                              href={whatsappUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button
                                size="md"
                                height="48px"
                                width="200px"
                                variant="ghost"
                                // color="#DCE2FF"
                                color={'#4CAF50'}
                                _hover={{ border: '2px solid #4CAF50' }}
                                leftIcon={
                                  <BsWhatsapp color="#1970F1" size="20px" />
                                }
                              >
                                WhatsApp
                              </Button>
                            </a>

                            <a href={telegramUrl}>
                              <Button
                                href={telegramUrl}
                                size="md"
                                height="48px"
                                width="200px"
                                variant="ghost"
                                color={'#4CAF50'}
                                _hover={{ border: '2px solid #4CAF50' }}
                                leftIcon={
                                  <MdEmail color="#1970F1" size="20px" />
                                }
                              >
                                Telegram
                              </Button>
                            </a>
                          </HStack>
                        </Box>
          </Box>
        </div>
      ) : (
        <div>
          <TradeNavbar />
          <Box textAlign="center" py={10} px={6} mt={16} bg="#e3eef3">
            <Flex justifyContent="center" alignItems="center">
              <BsCheckCircleFill size={'40px'} color={'#4CAF50'} />
            </Flex>
            <Heading as="h2" size="xl" mt={6} mb={2} color={'green.500'}>
              Transaction Completed
            </Heading>
            <Text color={'gray.500'}>
              You will be credited in a few minutes. You can stay in touch if
              you have any concern
            </Text>
          </Box>
          <Container
            bg="#e3eef3"
            maxW="full"
            mt={0}
            centerContent
            overflow="hidden"
          >
            <Flex>
              <Box
                bg="#191970"
                //   bg="rgb(203, 231, 240)"
                color="white"
                borderRadius="lg"
                m={{ sm: 4, md: 16, lg: 10 }}
                p={{ sm: 5, md: 5, lg: 16 }}
              >
                <Box p={4}>
                  <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
                    <WrapItem>
                      <Box>
                        <Heading>Contact</Heading>
                        <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.500">
                          Fill up the form below to contact us
                        </Text>
                        <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                          <VStack pl={0} spacing={3} alignItems="flex-start">
                            <a
                              href={whatsappUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button
                                size="md"
                                height="48px"
                                width="200px"
                                variant="ghost"
                                color="#DCE2FF"
                                _hover={{ border: '2px solid #1C6FEB' }}
                                leftIcon={
                                  <BsWhatsapp color="#1970F1" size="20px" />
                                }
                              >
                                WhatsApp
                              </Button>
                            </a>

                            <a href={`mailto:${email}`}>
                              <Button
                                href={`mailto:${email}`}
                                size="md"
                                height="48px"
                                width="200px"
                                variant="ghost"
                                color="#DCE2FF"
                                _hover={{ border: '2px solid #1C6FEB' }}
                                leftIcon={
                                  <MdEmail color="#1970F1" size="20px" />
                                }
                              >
                                {email}
                              </Button>
                            </a>

                            {/**<Button
                          size="md"
                          height="48px"
                          width="200px"
                          variant="ghost"
                          color="#DCE2FF"
                          _hover={{ border: "2px solid #1C6FEB" }}
                          leftIcon={
                            <MdLocationOn color="#1970F1" size="20px" />
                          }
                        >
                          Karnavati, India
                        </Button> */}
                          </VStack>
                        </Box>
                        {/**<HStack
                      mt={{ lg: 10, md: 10 }}
                      spacing={5}
                      px={5}
                      alignItems="flex-start"
                    >
                      <IconButton
                        aria-label="facebook"
                        variant="ghost"
                        size="lg"
                        isRound={true}
                        _hover={{ bg: "#0D74FF" }}
                        icon={<MdFacebook size="28px" />}
                      />
                      <IconButton
                        aria-label="github"
                        variant="ghost"
                        size="lg"
                        isRound={true}
                        _hover={{ bg: "#0D74FF" }}
                        icon={<BsGithub size="28px" />}
                      />
                      <IconButton
                        aria-label="discord"
                        variant="ghost"
                        size="lg"
                        isRound={true}
                        _hover={{ bg: "#0D74FF" }}
                        icon={<BsDiscord size="28px" />}
                      />
                    </HStack> */}
                      </Box>
                    </WrapItem>
                    <WrapItem>
                      <Box bg="white" borderRadius="lg">
                        <Box m={8} color="#0B0E3F">
                          <VStack spacing={5}>
                            <FormControl id="name">
                              <FormLabel>Your Name</FormLabel>
                              <InputGroup borderColor="#E0E1E7">
                                <InputLeftElement pointerEvents="none">
                                  <BsPerson color="gray.800" />
                                </InputLeftElement>
                                {/* <Input type="text" size="md" name="entry.89766816" /> */}
                                <Input
                                  type="text"
                                  size="md"
                                  name="entry.89766816"
                                  value={isUsername}
                                  onChange={e => setIsUsername(e.target.value)}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl id="name">
                              <FormLabel>Email</FormLabel>
                              <InputGroup borderColor="#E0E1E7">
                                <InputLeftElement pointerEvents="none">
                                  <MdOutlineEmail color="gray.800" />
                                </InputLeftElement>
                                {/* <Input
                              type="text"
                              size="md"
                              name="entry.987368572"
                            /> */}
                                <Input
                                  type="email"
                                  size="md"
                                  name="entry.987368572"
                                  value={isUserEmail}
                                  onChange={e => setIsUserEmail(e.target.value)}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl id="name">
                              <FormLabel>Phone</FormLabel>
                              <InputGroup borderColor="#E0E1E7">
                                <InputLeftElement pointerEvents="none">
                                  <MdPhoneInTalk color="gray.800" />
                                </InputLeftElement>
                                <Input
                                  type="phone"
                                  size="md"
                                  name="entry.1046938577"
                                  value={isUserPhone}
                                  onChange={e => setIsUserPhone(e.target.value)}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl id="name">
                              <FormLabel>Message</FormLabel>
                              <Textarea
                                borderColor="gray.300"
                                _hover={{
                                  borderRadius: 'gray.300',
                                }}
                                placeholder="message"
                                name="entry.159797155"
                                value={isUserMessage}
                                onChange={e => setIsUserMessage(e.target.value)}
                              />
                            </FormControl>
                            <FormControl id="name" float="right">
                              <Button
                                variant="solid"
                                bg="green.500"
                                color="white"
                                _hover={{}}
                                onClick={handleSubmit}
                              >
                                Send Message
                              </Button>
                            </FormControl>
                          </VStack>
                        </Box>
                      </Box>
                    </WrapItem>
                  </Wrap>
                </Box>
              </Box>
            </Flex>
          </Container>
        </div>
      )}
    </div>
  );
}
