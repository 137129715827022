import React from 'react';
import SellPayment from '../layout/trade/SellPayment';

const SellPaymentPage = () => {
  return (
      <div>
          <SellPayment />
      </div>
  )
}

export default SellPaymentPage