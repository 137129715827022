import React from 'react';
import {
    ChakraProvider,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Input,
    Button,
    HStack,
    Flex,
    Heading,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
  } from '@chakra-ui/react';

const Forbidden = () => {
  return (
    <ChakraProvider>
        <Flex  align="center" ml={100}>
        <div>
        
        <span
            className="header-and-warning"
            style={{
              borderRadius: '10px',
              borderColor: 'white',
              boxShadow: '2px 0px 4px 6px rgba(0, 0, 0, 0.1)',
              padding: '10px',
              position: 'fixed',
              top: '110px',
              zIndex: '100',
              // marginBottom: "200px",
            //   marginLeft: '500px'
            }}
          >
            <Text textAlign={'center'}>
              <strong>Forbidden Links</strong>: 
              {/* <strong style={{ backgroundColor: 'red' }}>Out of bound </strong>{' '} <br /><mark> unauthorised</mark> 
              <mark>persons</mark>. */}
            </Text>
            <a href='/chang3-rat3'>Forbidden link 1</a>
          </span>
    </div>
        </Flex>
       
    </ChakraProvider>
    
  )
}

export default Forbidden;
