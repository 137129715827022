import React, { useState, useEffect, useContext } from 'react';
import '../../styles/swap.css';
import SwapFormAggregation from '../trade/SwapFormAggregation';
import { useNavigate } from 'react-router-dom';
// import { ArrowDownUp } from "react-bootstrap-icons";
import { useRecoilState } from 'recoil';
import { isFiatOnTopAtom } from '../../../recoil/atoms/randomAtom';
import TradeNavbar from './SwapNavbar';
import { Button, Tooltip, Flex } from '@chakra-ui/react';
import {
  cryptoAmountAtom,
  fiatAmountAtom,
  sellRateAtom,
  buyRateAtom,
  selectedCryptoAtom,
  sellBankAtom,
  transactionFeeAtom,
  selectedBankAtom, lpFeeAtom, lpFeeRateAtom
} from '../../../recoil/atoms/rateAtom';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { exchangeRateContext } from '../../../context/Context';

function Swap() {

  // const { sellRate } = useContext(exchangeRateContext);
  const { sellRate, setSellRate } = useContext(exchangeRateContext);
  // const [inputAmount, setInputAmount] = useState(0);
  // const [sellRate] = useRecoilState(sellRateAtom);
  
  const [buyRate, setBuyRate] = useRecoilState(buyRateAtom);
  // const [totalSell, setTotalSell] = useState(0);
  // const [exchangeRate, setExchangeRate] = useState(sellRate || buyRate);
  const [transactionFee, setTransactionFee] =
    useRecoilState(transactionFeeAtom);
  const [isFiatOnTop, setIsFiatOnTop] = useRecoilState(isFiatOnTopAtom);
  const [cryptoAmount, setCryptoAmount] = useRecoilState(cryptoAmountAtom);
  const [fiatAmount, setFiatAmount] = useRecoilState(fiatAmountAtom);
  const [transactionType, setTransactionType] = useState('Sell');
  const [selectedCrypto, setSelectedCrypto] =
    useRecoilState(selectedCryptoAtom);
  //  const [sellBank, setSellBank] =
  // useRecoilState(sellBankAtom);
  // const [selectBank, setSelectBank] = useRecoilState(selectedBankAtom);
  const [isBuyButtonDisabled, setIsBuyButtonDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [totalLpFee, setTotalLpFee] = useRecoilState(lpFeeAtom);
  const [lpFeeRate, setLpFeeRate] = useRecoilState(lpFeeRateAtom);
  // const [totalLpFee, setTotalLpFee] = useState(0);
  // const [lpFeeRate, setLpFeeRate] = useState(6.66);
  

  const navigate = useNavigate();

  // console.log("sellRate in Swap.js", sellRate);

  // useEffect(() => {
  //   console.log("sellRate in Swap.js useEffect", sellRate)
  // }, [sellRate])

 


  const formattedNumber = (number) => {
    if (isNaN(number)) {
        return "Invalid number";
    }
    const formatted = parseFloat(number).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formatted.endsWith('.00') ? formatted.slice(0, -3) : formatted;
};



  const handleButtonClick = () => {
    if (transactionType === 'Sell') {
      navigate('/trade/sell');
    } else if (transactionType === 'Buy') {
      navigate('/trade/buy');
    }
  };

  


  const handleTransactionType = typeOfTrade => {
    setTransactionType(typeOfTrade);
  };

  const handleLiquidityPoolInfo = () => {
    setIsOpen(!isOpen);
  };

  // const handleBuyButtonClick = () => {
  //   // Add your logic here
  //   setIsFiatOnTop(false);
  //   handleTransactionType('Buy');
  //   setIsBuyButtonDisabled(true);
  // };

  useEffect(() => {
    let rateOfSelectedTransactionType;
    handleTransactionType(transactionType);
  }, []);



  useEffect(() => {
    const liquidityFee = cryptoAmount * lpFeeRate;

    setTotalLpFee(liquidityFee); 
}, [cryptoAmount, lpFeeRate]);





// useEffect(() => {
//   if (cryptoAmount < 50 && cryptoAmount !== '') {
//     setSellRate(sellRate + 60);
//   }else setSellRate(sellRate);

//   console.log("sellRate when below 50", sellRate)
// }, [sellRate, cryptoAmount]);

// useEffect(() => {
//   let newSellRate = sellRate; // Initialize newSellRate with current sellRate

//   // Check condition and update newSellRate accordingly
//   if (cryptoAmount < 50 && cryptoAmount !== '') {
//     newSellRate += 60; // Increment sellRate by 60
//   }

//   // Update sellRate only if it has changed
//   if (newSellRate !== sellRate) {
//     setSellRate(newSellRate);
//   }

  
// }, [sellRate, cryptoAmount]);

// console.log("sellRate when below 50", sellRate)


  

 

  return (
    <div className="App">
      <div className="appNav">
        <div className="toggle-button-container">
          <Button
            className={`toggle-button ${isFiatOnTop ? 'active' : ''}`}
            name="entry.1280747724"
            onClick={() => {
              setIsFiatOnTop(true);
              handleTransactionType('Sell');
            }}
          >
            Sell
          </Button>
          <Tooltip label="Coming Soon" hasArrow>
            <Button
              className={`toggle-button ${!isFiatOnTop ? 'active' : ''}`}
              name="entry.1280747724"
              // onClick={handleBuyButtonClick}
              disabled={isBuyButtonDisabled}
            >
              Buy
            </Button>
          </Tooltip>
        </div>
  
      </div>

      <div className="appBody">
        <div className="swapContainer">
          <div className="swapHeader">
            <span className="swapText">Trade</span>
          </div>
          <div className="swapBody">
            <SwapFormAggregation />
          </div>

         


          {cryptoAmount || fiatAmount ? (<div className="rateFooter">
            <div>
              <div className="feeContainer">
                <div>Rate  </div>
                {transactionType === 'Buy' ? (
                  <div>
                    1 {selectedCrypto} = {formattedNumber(buyRate)}
                  </div>
                ) : (
                  <div>
                    {' '}
                    1 {selectedCrypto} = &#8358;{formattedNumber(sellRate)}
                  </div>
                )}
              </div>
              {transactionType === 'Buy' ? (
                <div className="feeContainer">
                  <div className="ratioContainer" name="entry.1785407891">
                    Binance Smart Chain Fee :
                  </div>
                  <div className="ratioContainer" name="entry.1785407891">
                    {transactionFee} usdt
                  </div>
                </div>
              ) : (
                // <div className='feeContainer'>
                //   <div className="ratioContainer" name="entry.1785407891">
                //     Fee :
                //   </div>
                //   <div className="ratioContainer" name="entry.1785407891">
                //     {transactionFee} usdt
                //   </div>
                // </div>
                ''
              )}
              <div className="feeContainer">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  LP Fee{' '}
                  <Tooltip
                    label="This fee is charged by our liquidity providers to process payment. This varies depending on your transaction value"
                    hasArrow
                    isOpen={isOpen}
                  >
                    <p
                      onClick={handleLiquidityPoolInfo}
                      style={{ cursor: 'pointer', color: 'gray', marginLeft: '5px', marginRight: '5px' }}
                    >
                      &#9432;
                    </p>
                  </Tooltip>
                  
                </div>
                <div>{formattedNumber(totalLpFee)}</div>
              </div>
            </div>
          </div>) : ""}

          

        

          <div className="swapButtonContainer">
            {cryptoAmount <= 0 || isNaN(fiatAmount) || fiatAmount <= 0 ? (
              <button className="tradeButton-disabled" disabled>
                Trade
              </button>
            ) : (
              <button className="tradeButton" onClick={handleButtonClick}>
                Trade Now
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Swap;
