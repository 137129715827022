import React from 'react';

const Banks = () => {
  const listOfBanks = [
    { value: 1, label: 'Access Bank Plc', type: 'commercial bank' },
    { value: 2, label: 'Citibank Nigeria Limited', type: 'commercial bank' },
    { value: 3, label: 'Ecobank Nigeria Plc', type: 'commercial bank' },
    { value: 4, label: 'Fidelity Bank Plc', type: 'commercial bank' },
    { value: 5, label: 'First Bank Nigeria Limited', type: 'commercial bank' },
    {
      value: 6,
      label: 'First City Monument Bank Plc',
      type: 'commercial bank',
    },
    { value: 7, label: 'Globus Bank Limited', type: 'commercial bank' },
    { value: 8, label: 'Guaranty Trust Bank Plc', type: 'commercial bank' },
    {
      value: 9,
      label: 'Heritage Banking Company Ltd.',
      type: 'commercial bank',
    },
    { value: 10, label: 'Keystone Bank Limited', type: 'commercial bank' },
    { value: 11, label: 'Parallex Bank Ltd', type: 'commercial bank' },
    { value: 12, label: 'Polaris Bank Plc', type: 'commercial bank' },
    { value: 13, label: 'Premium Trust Bank', type: 'commercial bank' },
    { value: 14, label: 'Providus Bank', type: 'commercial bank' },
    { value: 15, label: 'Stanbic IBTC Bank Plc', type: 'commercial bank' },
    {
      value: 16,
      label: 'Standard Chartered Bank Nigeria Ltd.',
      type: 'commercial bank',
    },
    { value: 17, label: 'Sterling Bank Plc', type: 'commercial bank' },
    {
      value: 18,
      label: 'SunTrust Bank Nigeria Limited',
      type: 'commercial bank',
    },
    { value: 19, label: 'Titan Trust Bank Ltd', type: 'commercial bank' },
    { value: 20, label: 'Union Bank of Nigeria Plc', type: 'commercial bank' },
    { value: 21, label: 'United Bank For Africa Plc', type: 'commercial bank' },
    { value: 22, label: 'Unity Bank Plc', type: 'commercial bank' },
    { value: 23, label: 'Wema Bank Plc', type: 'commercial bank' },
    { value: 24, label: 'Zenith Bank Plc', type: 'commercial bank' },
    { value: 25, label: 'Alternative Bank Limited', type: 'Non-interest bank' },
    { value: 26, label: 'JAIZ BANK PLC', type: 'Non-interest bank' },
    { value: 27, label: 'LOTUS BANK LTD', type: 'Non-interest bank' },
    { value: 28, label: 'TAJ', type: 'Non-interest bank' },
    { value: 29, label: 'ABBEY MORTGAGE BANK', type: 'Primary Mortgage Bank' },
    { value: 30, label: 'AG HOMES', type: 'Primary Mortgage Bank' },
    { value: 31, label: 'AKWA SAVINGS', type: 'Primary Mortgage Bank' },
    { value: 32, label: 'ASO SAVINGS & LOANS', type: 'Primary Mortgage Bank' },
    {
      value: 33,
      label: 'BRENT (SKYFIELD) SAVINGS',
      type: 'Primary Mortgage Bank',
    },
    {
      value: 34,
      label: 'CENTAGE SAVINGS & LOANS',
      type: 'Primary Mortgage Bank',
    },
    { value: 35, label: 'CITY CODE', type: 'Primary Mortgage Bank' },
    { value: 36, label: 'COOP SAVINGS & LOANS', type: 'Primary Mortgage Bank' },
    {
      value: 37,
      label: 'DELTA MORTGAGE FINANCE',
      type: 'Primary Mortgage Bank',
    },
    { value: 38, label: 'FBN MORTGAGES', type: 'Primary Mortgage Bank' },
    { value: 39, label: 'FHA HOMES LTD.', type: 'Primary Mortgage Bank' },
    {
      value: 40,
      label: 'FIRST GENERATION HOMES',
      type: 'Primary Mortgage Bank',
    },
    { value: 41, label: 'GATEWAY SAVINGS', type: 'Primary Mortgage Bank' },
    { value: 42, label: 'GLOBAL TRUST', type: 'Primary Mortgage Bank' },
    { value: 43, label: 'HAGGAI MORTGAGE BANK', type: 'Primary Mortgage Bank' },
    { value: 44, label: 'HOME-BASE MORTGAGE', type: 'Primary Mortgage Bank' },
    { value: 45, label: 'IMPERIAL HOMES', type: 'Primary Mortgage Bank' },
    { value: 46, label: 'INFINITY TRUST', type: 'Primary Mortgage Bank' },
    {
      value: 47,
      label: 'JIGAWA SAVINGS & LOANS',
      type: 'Primary Mortgage Bank',
    },
    {
      value: 48,
      label: 'JUBILEE-LIFE MORTGAGE BANK',
      type: 'Primary Mortgage Bank',
    },
    { value: 49, label: 'KEBBI STATE HOMES', type: 'Primary Mortgage Bank' },
    {
      value: 50,
      label: 'LAGOS BUILDING & INVESTMENT',
      type: 'Primary Mortgage Bank',
    },
    { value: 51, label: "MAYFRESH MORTGAGE BANK", type: "Primary Mortgage Bank" },
  { value: 52, label: "MGSL MORTGAGE BANK", type: "Primary Mortgage Bank" },
  { value: 53, label: "MUTUAL ALLIANCE", type: "Primary Mortgage Bank" },
  { value: 54, label: "NEW PRUDENTIAL", type: "Primary Mortgage Bank" },
  { value: 55, label: "NIGERIA POLICE MORTGAGE BANK", type: "Primary Mortgage Bank" },
  { value: 56, label: "OMOLUABI (LIVINGSPRING)", type: "Primary Mortgage Bank" },
  { value: 57, label: "PLATINUM MORTGAGE BANK", type: "Primary Mortgage Bank" },
  { value: 58, label: "REFUGE HOMES", type: "Primary Mortgage Bank" },
  { value: 59, label: "STB BUILDING SOCIETY", type: "Primary Mortgage Bank" },
  { value: 60, label: "TRUSTBOND MORTGAGE BANK", type: "Primary Mortgage Bank" },
  { value: 61, label: "9 PSB Ltd", type: "Payment Service Bank" },
  { value: 62, label: "Hope PSB Ltd", type: "Payment Service Bank" },
  { value: 63, label: "Moneymaster PSB Ltd", type: "Payment Service Bank" },
  { value: 64, label: "MoMo PSB", type: "Payment Service Bank" },
  { value: 65, label: "Smartcash PSB", type: "Payment Service Bank" },
  { value: 66, label: "CORONATION MERCHANT BANK", type: "Merchant Bank" },
  { value: 67, label: "FBN MERCHANT BANK", type: "Merchant Bank" },
  { value: 68, label: "FSDH MERCHANT BANK", type: "Merchant Bank" },
  { value: 69, label: "GREENWICH MERCHANT BANK", type: "Merchant Bank" },
  { value: 70, label: "NOVA MERCHANT BANK", type: "Merchant Bank" },
  { value: 71, label: "RAND MERCHANT BANK", type: "Merchant Bank" },
  { value: 72, label: "AACB Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 73, label: "AB Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 74, label: "Abia SME Microfinance Bank", type: "Microfinance Bank" },
  { value: 75, label: "Alert Microfinance Bank", type: "Microfinance Bank" },
  { value: 76, label: "Abia State University Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 77, label: "Abigi Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 78, label: "Above Only Microfinance Bank", type: "Microfinance Bank" },
  { value: 79, label: "Abubakar Tafawa Balewa University (ATBU) Microfinance Bank", type: "Microfinance Bank" },
  { value: 80, label: "Abucoop Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 81, label: "Abulesoro Microfinance Bank", type: "Microfinance Bank" },
  { value: 82, label: "Accion Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 83, label: "ACE Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 84, label: "Achina Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 85, label: "ADA Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 86, label: "Adazi-Enu Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 87, label: "Adazi-Nnukwu Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 88, label: "Addosser Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 89, label: "Adeyemi College Staff Microfinance Bank", type: "Microfinance Bank" },
  { value: 90, label: "Afekhafe Microfinance Bank", type: "Microfinance Bank" },
  { value: 91, label: "Afemai Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 92, label: "Agosasa Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 93, label: "Ahmadu Bello University Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 94, label: "Aibio Microfinance Bank", type: "Microfinance Bank" },
  { value: 95, label: "Ajewole Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 96, label: "Ajose Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 97, label: "Ajuta Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 98, label: "Akalabo Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 99, label: "Akokwa Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 100, label: "Akpo Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 101, label: "Aku Diewa Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 102, label: "Al-Barakah Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 103, label: "Albasu Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 104, label: "Alekun Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 105, label: "Algreb Microfinance Bank", type: "Microfinance Bank" },
  { value: 106, label: "Al-Hayat Microfinance Bank", type: "Microfinance Bank" },
  { value: 107, label: "Aliero Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 108, label: "All Workers Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 109, label: "Alor Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 110, label: "Alpha Kapital Microfinance Bank", type: "Microfinance Bank" },
  { value: 111, label: "Alvana Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 112, label: "AMAC Microfinance Bank", type: "Microfinance Bank" },
  { value: 113, label: "Amazu Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 114, label: "Amegy Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 115, label: "AMJU-Unique Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 116, label: "AMML Microfinance Bank Limited (Abuja Markets Management Ltd)", type: "Microfinance Bank" },
  { value: 117, label: "Ampersand Microfinance Bank", type: "Microfinance Bank" },
  { value: 118, label: "Amucha Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 119, label: "Anchorage Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 120, label: "Aniocha Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 121, label: "Annointed Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 122, label: "Aogo Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 123, label: "Apa Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 124, label: "Apex Trust Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 125, label: "Apple Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 126, label: "Aramoko Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 127, label: "Aris Microfinance Bank", type: "Microfinance Bank" },
  { value: 128, label: "Asha Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 129, label: "Aspire Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 130, label: "Asset Matrix Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 131, label: "Assets Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 132, label: "Tangerine Money Microfinance Bank", type: "Microfinance Bank" },
  { value: 133, label: "Astra Polaris Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 134, label: "Auchi Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 135, label: "Avunegbe Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 136, label: "Awe Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 137, label: "Awka-Etiti Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 138, label: "Awkuzu Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 139, label: "Suntop Microfinance Bank", type: "Microfinance Bank" },
  { value: 140, label: "AZSA Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 141, label: "B C Kash Microfinance Bank", type: "Microfinance Bank" },
  { value: 142, label: "Babba Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 143, label: "Babcock Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 144, label: "Babura Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 145, label: "Baines Credit Microfinance bank", type: "Microfinance Bank" },
  { value: 146, label: "Balera Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 147, label: "Balogun Fulani Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 148, label: "Balogun Gambari Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 149, label: "Bam Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 150, label: "Banex Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 151, label: "Baobab Microfinance Bank", type: "Microfinance Bank" },
  { value: 152, label: "Barnawa Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 153, label: "Bauchi CFA Mirofinance Bank Limited", type: "Microfinance Bank" },
  { value: 154, label: "Bauchi Investment Corporation MFB Limited (BIC)", type: "Microfinance Bank" },
  { value: 155, label: "Bawyi Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 156, label: "Bayajidda Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 157, label: "Bayero University Microfinance Bank", type: "Microfinance Bank" },
  { value: 158, label: "Bayetin Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 159, label: "Bejin-Doko Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 160, label: "Benue Microfinance Bank", type: "Microfinance Bank" },
  { value: 161, label: "Benysta Microfinance Bank", type: "Microfinance Bank" },
  { value: 162, label: "Berachah Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 163, label: "Best Star Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 164, label: "Bethel Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 165, label: "Better Life Microfinance Bank", type: "Microfinance Bank" },
  { value: 166, label: "Bichi Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 167, label: "Bida Polytechnic Microfinance Bank", type: "Microfinance Bank" },
  { value: 168, label: "BLUCON MFB", type: "Microfinance Bank" },
  { value: 169, label: "BLUE RIDGE MFB", type: "Microfinance Bank" },
  { value: 170, label: "BLUEPRINT INVESTMENTS MFB", type: "Microfinance Bank" },
  { value: 171, label: "BOCTRUST MFB", type: "Microfinance Bank" },
  { value: 172, label: "BOI MFB", type: "Microfinance Bank" },
  { value: 173, label: "BOJI BOJI MFB LTD.", type: "Microfinance Bank" },
  { value: 174, label: "BOKKOS MFB", type: "Microfinance Bank" },
  { value: 175, label: "BOMPAI MFB", type: "Microfinance Bank" },
  { value: 176, label: "BONGHE MFB", type: "Microfinance Bank" },
  { value: 177, label: "BORGU MFB", type: "Microfinance Bank" },
  { value: 178, label: "BORNO RENAISSANCE MFB LTD.", type: "Microfinance Bank" },
  { value: 179, label: "BOROMU MFB", type: "Microfinance Bank" },
  { value: 180, label: "BORSTAL MFB", type: "Microfinance Bank" },
  { value: 181, label: "BOSAK MFB", type: "Microfinance Bank" },
  { value: 182, label: "BOWEN MFB", type: "Microfinance Bank" },
  { value: 183, label: "BOWMAN MFB", type: "Microfinance Bank" },
  { value: 184, label: "BRETHREN MFB", type: "Microfinance Bank" },
  { value: 185, label: "BRIGHTWAY MFB LTD", type: "Microfinance Bank" },
  { value: 186, label: "BROADVIEW MFB", type: "Microfinance Bank" },
  { value: 187, label: "BROOKS MFB", type: "Microfinance Bank" },
  { value: 188, label: "BUBAYERO MFB LTD", type: "Microfinance Bank" },
  { value: 189, label: "BUNDI MFB", type: "Microfinance Bank" },
  { value: 190, label: "BUNGUDU MFB", type: "Microfinance Bank" },
  { value: 191, label: "BUNKASA MFB", type: "Microfinance Bank" },
  { value: 192, label: "BUNKURE MFB", type: "Microfinance Bank" },
  { value: 193, label: "BUSU MFB", type: "Microfinance Bank" },
  { value: 194, label: "CANAAN MFB", type: "Microfinance Bank" },
  { value: 195, label: "CAPITALMETRIQ SWIFT MFB", type: "Microfinance Bank" },
  { value: 196, label: "CAPSTONE MFB", type: "Microfinance Bank" },
  { value: 197, label: "CARBON MFB", type: "Microfinance Bank" },
  { value: 198, label: "CARETAKER MFB", type: "Microfinance Bank" },
  { value: 199, label: "CASH RITE MFB", type: "Microfinance Bank" },
  { value: 200, label: "CASHCONNECT MFB", type: "Microfinance Bank" },
  { value: 201, label: "CASTLE MFB", type: "Microfinance Bank" },
  { value: 202, label: "CATLAND MFB", type: "Microfinance Bank" },
  { value: 203, label: "CEDAR MFB", type: "Microfinance Bank" },
  { value: 204, label: "CHALLENGE MFB", type: "Microfinance Bank" },
  { value: 205, label: "CHANELLE MFB", type: "Microfinance Bank" },
  { value: 206, label: "CHANGAN RTS MFB", type: "Microfinance Bank" },
  { value: 207, label: "CHASE MFB LTD", type: "Microfinance Bank" },
  { value: 208, label: "CHEVRON EMPLOYEE CO-OPERATIVE MFB (CEMCS)", type: "Microfinance Bank" },
  { value: 209, label: "CHIBUEZE MFB", type: "Microfinance Bank" },
  { value: 210, label: "CHIKUM MFB", type: "Microfinance Bank" },
  { value: 211, label: "CHRISORE MFB", type: "Microfinance Bank" },
  { value: 212, label: "CHRONICLES MFB", type: "Microfinance Bank" },
  { value: 213, label: "CHUKWUNENYE MFB", type: "Microfinance Bank" },
  { value: 214, label: "CINTRUST MFB", type: "Microfinance Bank" },
  { value: 215, label: "CIT MFB", type: "Microfinance Bank" },
  { value: 216, label: "CITIZEN TRUST MFB", type: "Microfinance Bank" },
  { value: 217, label: "CITY MISSION METHODIST MFB", type: "Microfinance Bank" },
  { value: 218, label: "CIVIC MFB", type: "Microfinance Bank" },
  { value: 219, label: "CLOVERLEAF MFB", type: "Microfinance Bank" },
  { value: 220, label: "COAL CAMP MFB", type: "Microfinance Bank" },
  { value: 221, label: "COASTLINE MFB", type: "Microfinance Bank" },
  { value: 222, label: "COEMINNA MFB", type: "Microfinance Bank" },
  { value: 223, label: "CONFIDENCE MFB", type: "Microfinance Bank" },
  { value: 224, label: "KREDI MONEY MFB (Formerly CONPRO MFB)", type: "Microfinance Bank" },
  { value: 225, label: "CONSISTENT TRUST MFB", type: "Microfinance Bank" },
  { value: 226, label: "CONSUMER MFB", type: "Microfinance Bank" },
  { value: 227, label: "CORESTEP MFB", type: "Microfinance Bank" },
  { value: 228, label: "COVENANT MFB", type: "Microfinance Bank" },
  { value: 229, label: "CREEKLINE MFB", type: "Microfinance Bank" },
  { value: 230, label: "CRESCENT MFB", type: "Microfinance Bank" },
  { value: 231, label: "CREST MFB", type: "Microfinance Bank" },
  { value: 232, label: "CROSSRIVER MFB", type: "Microfinance Bank" },
  { value: 233, label: "CRUTECH MFB", type: "Microfinance Bank" },
  { value: 234, label: "CRYSTABEL MFB", type: "Microfinance Bank" },
  { value: 235, label: "CSD MFB", type: "Microfinance Bank" },
  { value: 236, label: "CUSTODIAN MFB", type: "Microfinance Bank" },
  { value: 237, label: "DAILY-TRUST MFB", type: "Microfinance Bank" },
  { value: 238, label: "DAL MFB", type: "Microfinance Bank" },
  { value: 239, label: "DAMBATTA- MAKODA MFB", type: "Microfinance Bank" },
  { value: 240, label: "DAVODANI MFB", type: "Microfinance Bank" },
  { value: 241, label: "DAWAKIN KUDU MFB", type: "Microfinance Bank" },
  { value: 242, label: "DAWAKIN TOFA MFB", type: "Microfinance Bank" },
  { value: 243, label: "DAYLIGHT MFB LTD", type: "Microfinance Bank" },
  { value: 244, label: "DEC-ENUGU MFB", type: "Microfinance Bank" },
  { value: 245, label: "DESTINY MFB", type: "Microfinance Bank" },
  { value: 246, label: "DIOBU MFB", type: "Microfinance Bank" },
  { value: 247, label: "DOGUWA MFB", type: "Microfinance Bank" },
  { value: 248, label: "DOLPHIN MFB", type: "Microfinance Bank" },
  { value: 249, label: "DORBEN MFB", type: "Microfinance Bank" },
  { value: 250, label: "DUTSE MFB", type: "Microfinance Bank" },
  { value: 251, label: "EAGLE FLIGHT MFB", type: "Microfinance Bank" },
  { value: 252, label: "EARNWELL MFB", type: "Microfinance Bank" },
  { value: 253, label: "EAST GATE MFB", type: "Microfinance Bank" },
  { value: 254, label: "EASTMAN MFB", type: "Microfinance Bank" },
  { value: 255, label: "EASY ACCESS MFB LTD", type: "Microfinance Bank" },
  { value: 256, label: "e-BARCS MFB", type: "Microfinance Bank" },
  { value: 257, label: "EBEDI MFB", type: "Microfinance Bank" },
  { value: 258, label: "EBONYI STATE UNIVERSITY MFB (EBSU)", type: "Microfinance Bank" },
  { value: 259, label: "EDFIN MFB", type: "Microfinance Bank" },
  { value: 260, label: "EDO MFB", type: "Microfinance Bank" },
  { value: 261, label: "EGBE MFB", type: "Microfinance Bank" },
  { value: 262, label: "EGBEMOHA MFB", type: "Microfinance Bank" },
  { value: 263, label: "EGWAFIN MFB", type: "Microfinance Bank" },
  { value: 264, label: "EJINDU MFB", type: "Microfinance Bank" },
  { value: 265, label: "EKIMOGUN MFB", type: "Microfinance Bank" },
  { value: 266, label: "EKONDO MFB", type: "Microfinance Bank" },
  { value: 267, label: "EK-RELIABLE MFB", type: "Microfinance Bank" },
  { value: 268, label: "EKWULOBIA MFB", type: "Microfinance Bank" },
  { value: 269, label: "ELO-EMEDE MFB", type: "Microfinance Bank" },
  { value: 270, label: "EMERALD MFB", type: "Microfinance Bank" },
  { value: 271, label: "EMPIRE TRUST MFB", type: "Microfinance Bank" },
  { value: 272, label: "ENDWELL MFB", type: "Microfinance Bank" },
  { value: 273, label: "ENEDE MFB LTD", type: "Microfinance Bank" },
  { value: 274, label: "ENRICH MFB", type: "Microfinance Bank" },
  { value: 275, label: "EQUATOR MFB", type: "Microfinance Bank" },
  { value: 276, label: "EQUINOX MFB", type: "Microfinance Bank" },
  { value: 277, label: "ESO-E MFB", type: "Microfinance Bank" },
  { value: 278, label: "EVERGREEN MFB", type: "Microfinance Bank" },
  { value: 279, label: "EWT MFB", type: "Microfinance Bank" },
  { value: 280, label: "EXCEL MFB", type: "Microfinance Bank" },
  { value: 281, label: "EZEBO MFB", type: "Microfinance Bank" },
  { value: 282, label: "FAIDA MFB LTD", type: "Microfinance Bank" },
  { value: 283, label: "FAIRMONEY MFB LTD", type: "Microfinance Bank" },
  { value: 284, label: "FAME MFB", type: "Microfinance Bank" },
  { value: 285, label: "FAST MFB", type: "Microfinance Bank" },
  { value: 286, label: "FCMB MFB LTD.", type: "Microfinance Bank" },
  { value: 287, label: "FECETU MFB", type: "Microfinance Bank" },
  { value: 288, label: "FEDERAL POLYTECHNIC, NEKEDE MFB LTD", type: "Microfinance Bank" },
  { value: 289, label: "FEDETH CO-OP MFB", type: "Microfinance Bank" },
  { value: 290, label: "FEDPOLY NASARAWA MFB", type: "Microfinance Bank" },
  { value: 291, label: "FFS MFB", type: "Microfinance Bank" },
  { value: 292, label: "FIDFUND MFB", type: "Microfinance Bank" },
  { value: 293, label: "FIMS MFB", type: "Microfinance Bank" },
  { value: 294, label: "FINATRUST MFB", type: "Microfinance Bank" },
  { value: 295, label: "FINCA MFB", type: "Microfinance Bank" },
  { value: 296, label: "FINEX MFB", type: "Microfinance Bank" },
  { value: 297, label: "FIRMUS MFB", type: "Microfinance Bank" },
  { value: 298, label: "FIRST ACCESS MFB LTD", type: "Microfinance Bank" },
  { value: 299, label: "FIRST HERITAGE MFB", type: "Microfinance Bank" },
  { value: 300, label: "FIRST LOW-LAND MFB", type: "Microfinance Bank" },
  { value: 301, label: "FIRST MUTUAL MFB", type: "Microfinance Bank" },
  { value: 302, label: "FIRST OPTION MFB", type: "Microfinance Bank" },
  { value: 303, label: "FIRST ROYAL MFB", type: "Microfinance Bank" },
  { value: 304, label: "FLOURISH MFB", type: "Microfinance Bank" },
  { value: 305, label: "FORESIGHT MFB", type: "Microfinance Bank" },
  { value: 306, label: "FORTRESS MFB", type: "Microfinance Bank" },
  { value: 307, label: "FORWARD MFB", type: "Microfinance Bank" },
  { value: 308, label: "FRONTLINE MFB", type: "Microfinance Bank" },
  { value: 309, label: "FUD MFB", type: "Microfinance Bank" },
  { value: 310, label: "Fullrange Microfinance Bank Ltd", type: "Microfinance Bank" },
  { value: 311, label: "FUT MINNA", type: "Microfinance Bank" },
  { value: 312, label: "FUTO MFB", type: "Microfinance Bank" },
  { value: 313, label: "GAA AKANBI MFB", type: "Microfinance Bank" },
  { value: 314, label: "GABASAWA MFB", type: "Microfinance Bank" },
  { value: 315, label: "GAMAWA MFB", type: "Microfinance Bank" },
  { value: 316, label: "GAREWA MFB", type: "Microfinance Bank" },
  { value: 317, label: "GARKI MFB", type: "Microfinance Bank" },
  { value: 318, label: "GARU MALLAM MFB", type: "Microfinance Bank" },
  { value: 319, label: "GASHUA MFB", type: "Microfinance Bank" },
  { value: 320, label: "GAYA MFB", type: "Microfinance Bank" },
  { value: 321, label: "GBEDE MFB", type: "Microfinance Bank" },
  { value: 322, label: "GEZAWA MFB", type: "Microfinance Bank" },
  { value: 323, label: "GIANT STRIDE MFB LTD", type: "Microfinance Bank" },
  { value: 324, label: "Gidauniya Alheri MFB", type: "Microfinance Bank" },
  { value: 325, label: "GIGINYA MFB", type: "Microfinance Bank" },
  { value: 326, label: "GIREI MFB", type: "Microfinance Bank" },
  { value: 327, label: "GIWA MFB", type: "Microfinance Bank" },
  { value: 328, label: "GLOBAL INITIATIVE MFB", type: "Microfinance Bank" },
  { value: 329, label: "GLORY MFB", type: "Microfinance Bank" },
  { value: 330, label: "GMB MFB", type: "Microfinance Bank" },
  { value: 331, label: "GOBARAU MFB", type: "Microfinance Bank" },
  { value: 332, label: "GOLD MFB", type: "Microfinance Bank" },
  { value: 333, label: "GOLDEN CHOICE MFB", type: "Microfinance Bank" },
  { value: 334, label: "GOLDMAN MFB", type: "Microfinance Bank" },
  { value: 335, label: "GOMBE MFB", type: "Microfinance Bank" },
  { value: 336, label: "GOOD NEIGHBOURS MFB", type: "Microfinance Bank" },
  { value: 337, label: "GOOD NEWS MFB", type: "Microfinance Bank" },
  { value: 338, label: "GOOD SHEPHERD MFB", type: "Microfinance Bank" },
  { value: 339, label: "GOWANS MFB", type: "Microfinance Bank" },
  { value: 340, label: "GRANTS MFB", type: "Microfinance Bank" },
  { value: 341, label: "GREEN ACRES MFB", type: "Microfinance Bank" },
  { value: 342, label: "GREEN ENERGY MFB", type: "Microfinance Bank" },
  { value: 343, label: "GREEN-BANK MFB", type: "Microfinance Bank" },
  { value: 344, label: "GREENVILLE MFB", type: "Microfinance Bank" },
  { value: 345, label: "GROOMING MFB", type: "Microfinance Bank" },
  { value: 346, label: "GTI MFB", type: "Microfinance Bank" },
  { value: 347, label: "GUDUSISA MFB", type: "Microfinance Bank" },
  { value: 348, label: "GULFARE MFB", type: "Microfinance Bank" },
  { value: 349, label: "GWONG MFB", type: "Microfinance Bank" },
  { value: 350, label: "HADEIJA MFB", type: "Microfinance Bank" },
  { value: 351, label: "HALAL CREDIT MFB", type: "Microfinance Bank" },
  { value: 352, label: "HALLOWED MFB", type: "Microfinance Bank" },
  { value: 353, label: "HAMADA MFB", type: "Microfinance Bank" },
  { value: 354, label: "HAMDA MFB", type: "Microfinance Bank" },
  { value: 355, label: "HASAL MFB", type: "Microfinance Bank" },
  { value: 356, label: "HEADWAY MFB LTD", type: "Microfinance Bank" },
  { value: 357, label: "HIGHLAND MFB", type: "Microfinance Bank" },
  { value: 358, label: "HINACHE MFB", type: "Microfinance Bank" },
  { value: 359, label: "HONEY MFB", type: "Microfinance Bank" },
  { value: 360, label: "HOPE ALIVE MFB", type: "Microfinance Bank" },
  { value: 361, label: "IBETO MFB", type: "Microfinance Bank" },
  { value: 362, label: "IBILE MFB", type: "Microfinance Bank" },
  { value: 363, label: "IBOM FADAMA MFB", type: "Microfinance Bank" },
  { value: 364, label: "IBOLO MFB", type: "Microfinance Bank" },
  { value: 365, label: "IBRAHIM BADAMASI BABANGIDA UNIVERSITY MFB", type: "Microfinance Bank" },
  { value: 366, label: "IBU-AJE MFB", type: "Microfinance Bank" },
  { value: 367, label: "I-Care Microfinance Bank Ltd", type: "Microfinance Bank" },
  { value: 368, label: "ICHI MFB", type: "Microfinance Bank" },
  { value: 369, label: "IDEATO MFB", type: "Microfinance Bank" },
  { value: 370, label: "IDEMILI MFB", type: "Microfinance Bank" },
  { value: 371, label: "IFEDAPO MFB", type: "Microfinance Bank" },
  { value: 372, label: "IFELODUN MFB", type: "Microfinance Bank" },
  { value: 373, label: "IGBOORA MFB", type: "Microfinance Bank" },
  { value: 374, label: "IGBO-UKWU MFB", type: "Microfinance Bank" },
  { value: 375, label: "IHIALA MFB", type: "Microfinance Bank" },
  { value: 376, label: "IJARE MFB", type: "Microfinance Bank" },
  { value: 377, label: "IJEBU IFE MFB", type: "Microfinance Bank" },
  { value: 378, label: "IKENNE MFB", type: "Microfinance Bank" },
  { value: 379, label: "IKIRE MFB", type: "Microfinance Bank" },
  { value: 380, label: "IKORODU DIVISION MFB", type: "Microfinance Bank" },
  { value: 381, label: "IKOYI-ILE MFB", type: "Microfinance Bank" },
  { value: 382, label: "IKOYI-OSUN MFB", type: "Microfinance Bank" },
  { value: 383, label: "ILARO POLYTECHNIC MFB", type: "Microfinance Bank" },
  { value: 384, label: "ILE-OLUJI MFB", type: "Microfinance Bank" },
  { value: 385, label: "ILISAN MFB", type: "Microfinance Bank" },
  { value: 386, label: "ILOBU MFB", type: "Microfinance Bank" },
  { value: 387, label: "ILOFFA MFB", type: "Microfinance Bank" },
  { value: 388, label: "ILORA MFB", type: "Microfinance Bank" },
  { value: 389, label: "ILORIN MFB", type: "Microfinance Bank" },
  { value: 390, label: "ILUDUN ORO MFB", type: "Microfinance Bank" },
  { value: 391, label: "ILUTUNTUN-OSORO MFB", type: "Microfinance Bank" },
  { value: 392, label: "IMO STATE MICROFINANCE BANK", type: "Microfinance Bank" },
  { value: 393, label: "IMODI-IMOSAN MFB", type: "Microfinance Bank" },
  { value: 394, label: "IMOPOLY MFB", type: "Microfinance Bank" },
  { value: 395, label: "IMOWO MFB", type: "Microfinance Bank" },
  { value: 396, label: "IMSU MFB", type: "Microfinance Bank" },
  { value: 397, label: "IMT MFB", type: "Microfinance Bank" },
  { value: 398, label: "INFINITY MFB", type: "Microfinance Bank" },
  { value: 399, label: "INSIGHT MFB", type: "Microfinance Bank" },
  { value: 400, label: "IPAPO MFB", type: "Microfinance Bank" },
  { value: 401, label: "IPERU MFB", type: "Microfinance Bank" },
  { value: 402, label: "IRL MFB", type: "Microfinance Bank" },
  { value: 403, label: "ISALE OYO MFB", type: "Microfinance Bank" },
  { value: 404, label: "ISHIAGU MFB", type: "Microfinance Bank" },
  { value: 405, label: "ISHIE MFB", type: "Microfinance Bank" },
  { value: 406, label: "ISI-UZO MFB", type: "Microfinance Bank" },
  { value: 407, label: "ISLAND MFB", type: "Microfinance Bank" },
  { value: 408, label: "ISUOFIA MFB", type: "Microfinance Bank" },
  { value: 409, label: "ISUA MFB", type: "Microfinance Bank" },
  { value: 410, label: "IWA MFB", type: "Microfinance Bank" },
  { value: 411, label: "IWADE MFB", type: "Microfinance Bank" },
  { value: 412, label: "IWOYE-IJESA MFB", type: "Microfinance Bank" },
  { value: 413, label: "IYAMOYE MFB", type: "Microfinance Bank" },
  { value: 414, label: "IYERU OKIN MFB", type: "Microfinance Bank" },
  { value: 415, label: "IYIN EKITI MFB", type: "Microfinance Bank" },
  { value: 416, label: "IZON MFB LTD", type: "Microfinance Bank" },
  { value: 417, label: "JANMAA MFB", type: "Microfinance Bank" },
  { value: 418, label: "JANZAMA MFB", type: "Microfinance Bank" },
  { value: 419, label: "JEN MFB", type: "Microfinance Bank" },
  { value: 420, label: "JESSEFIELD MFB", type: "Microfinance Bank" },
  { value: 421, label: "JEWEL COOP MFB", type: "Microfinance Bank" },
  { value: 422, label: "KABO MFB", type: "Microfinance Bank" },
  { value: 423, label: "KADPOLY MFB", type: "Microfinance Bank" },
  { value: 424, label: "KADUPE MFB", type: "Microfinance Bank" },
  { value: 425, label: "KAMBA MFB", type: "Microfinance Bank" },
  { value: 426, label: "KANKIA MFB LTD", type: "Microfinance Bank" },
  { value: 427, label: "KANO POLY MFB", type: "Microfinance Bank" },
  { value: 428, label: "KANO-WEST MFB", type: "Microfinance Bank" },
  { value: 429, label: "KATAGUM MFB", type: "Microfinance Bank" },
  { value: 430, label: "KAYVEE MFB", type: "Microfinance Bank" },
  { value: 431, label: "KC MFB", type: "Microfinance Bank" },
  { value: 432, label: "KCMB MFB", type: "Microfinance Bank" },
  { value: 433, label: "KENECHUKWU MFB", type: "Microfinance Bank" },
  { value: 434, label: "KHB MFB", type: "Microfinance Bank" },
  { value: 435, label: "KINGDOM COLLEGE MFB LTD", type: "Microfinance Bank" },
  { value: 436, label: "KISI MFB", type: "Microfinance Bank" },
  { value: 437, label: "KKU Microfinance", type: "Microfinance Bank" },
  { value: 438, label: "KMC MFB", type: "Microfinance Bank" },
  { value: 439, label: "KONTAGORA MFB", type: "Microfinance Bank" },
  { value: 440, label: "KOPO-KOPE MFB", type: "Microfinance Bank" },
  { value: 441, label: "KUDA MFB", type: "Microfinance Bank" },
  { value: 442, label: "KUMBOTSO MFB", type: "Microfinance Bank" },
  { value: 443, label: "KUNAGABA MFB", type: "Microfinance Bank" },
  { value: 444, label: "KUNCHI MFB", type: "Microfinance Bank" },
  { value: 445, label: "KURA MFB", type: "Microfinance Bank" },
  { value: 446, label: "KUSALLA MFB", type: "Microfinance Bank" },
  { value: 447, label: "KWACOFOCUS MFB", type: "Microfinance Bank" },
  { value: 448, label: "KWASU MFB", type: "Microfinance Bank" },
  { value: 449, label: "KWATASHI MFB", type: "Microfinance Bank" },
  { value: 450, label: "LA FAYETTE MFB", type: "Microfinance Bank" },
  { value: 451, label: "LANDGOLD MFB", type: "Microfinance Bank" },
  { value: 452, label: "LAPO MFB", type: "Microfinance Bank" },
  { value: 453, label: "LAVENDER", type: "Microfinance Bank" },
  { value: 454, label: "LAWYERS MFB", type: "Microfinance Bank" },
  { value: 455, label: "LAYELU MFB", type: "Microfinance Bank" },
  { value: 456, label: "LEADCITY MFB", type: "Microfinance Bank" },
  { value: 457, label: "LEGACY MFB", type: "Microfinance Bank" },
  { value: 458, label: "LEGEND MFB", type: "Microfinance Bank" },
  { value: 459, label: "LETSHEGO MFB", type: "Microfinance Bank" },
  { value: 460, label: "LEVITE MFB", type: "Microfinance Bank" },
  { value: 461, label: "LIFEGATE MFB", type: "Microfinance Bank" },
  { value: 462, label: "LIGHT MFB", type: "Microfinance Bank" },
  { value: 463, label: "LINKS MFB", type: "Microfinance Bank" },
  { value: 464, label: "LIVING SPRING MFB", type: "Microfinance Bank" },
  { value: 465, label: "LOBREM MFB", type: "Microfinance Bank" },
  { value: 466, label: "LOVONUS MFB", type: "Microfinance Bank" },
  { value: 467, label: "M & M MFB", type: "Microfinance Bank" },
  { value: 468, label: "MAB ALLIANZ MFB LTD", type: "Microfinance Bank" },
  { value: 469, label: "MACROD MFB", type: "Microfinance Bank" },
  { value: 470, label: "MADELYN MFB", type: "Microfinance Bank" },
  { value: 471, label: "MADOBI MFB", type: "Microfinance Bank" },
  { value: 472, label: "MAGAJIN-GIRI MFB", type: "Microfinance Bank" },
  { value: 473, label: "MAGNET MFB", type: "Microfinance Bank" },
  { value: 474, label: "MAIGATARI MFB", type: "Microfinance Bank" },
  { value: 475, label: "MAIN TRUST MFB", type: "Microfinance Bank" },
  { value: 476, label: "MAINLAND MFB", type: "Microfinance Bank" },
  { value: 477, label: "MAINSTREET MFB", type: "Microfinance Bank" },
  { value: 478, label: "MALACHY MFB", type: "Microfinance Bank" },
  { value: 479, label: "MAPOLY MFB", type: "Microfinance Bank" },
  { value: 480, label: "MARITIME MFB", type: "Microfinance Bank" },
  { value: 481, label: "MATAZU MFB LTD", type: "Microfinance Bank" },
  { value: 482, label: "MAXITRUST MFB", type: "Microfinance Bank" },
  { value: 483, label: "MBAITOLI MFB", type: "Microfinance Bank" },
  { value: 484, label: "MBAWULU MFB", type: "Microfinance Bank" },
  { value: 485, label: "MEGA MFB", type: "Microfinance Bank" },
  { value: 486, label: "MEGAPRAISE MFB", type: "Microfinance Bank" },
  { value: 487, label: "MEMENTO MFB LTD", type: "Microfinance Bank" },
  { value: 488, label: "MEMPHIS MICROFINANCE BANK LIMITED", type: "Microfinance Bank" },
  { value: 489, label: "MERCHANT MFB", type: "Microfinance Bank" },
  { value: 490, label: "MERIDIAN MFB", type: "Microfinance Bank" },
  { value: 491, label: "MGBIDI MFB", type: "Microfinance Bank" },
  { value: 492, label: "MICHEAL OKPARA UNIVERSITY MFB", type: "Microfinance Bank" },
  { value: 493, label: "MICROVIS MFB", type: "Microfinance Bank" },
  { value: 494, label: "MIDLAND MFB", type: "Microfinance Bank" },
  { value: 495, label: "MINJIBIR MFB", type: "Microfinance Bank" },
  { value: 496, label: "MINJI-SE CHURCHILL MFB", type: "Microfinance Bank" },
  { value: 497, label: "MKOBO MFB LTD", type: "Microfinance Bank" },
  { value: 498, label: "MODEL MFB", type: "Microfinance Bank" },
  { value: 499, label: "MODIYA YEKOKPE MFB", type: "Microfinance Bank" },
  { value: 500, label: "MOLUSI MFB", type: "Microfinance Bank" },
  { value: 501, label: "MONARCH MFB", type: "Microfinance Bank" },
  { value: 502, label: "MONEYTRONICS MFB (Formerly Ipodo-Ikeja MFB)", type: "Microfinance Bank" },
  { value: 503, label: "Moneytrust MFB", type: "Microfinance Bank" },
  { value: 504, label: "MOYOFADE MFB", type: "Microfinance Bank" },
  { value: 505, label: "MOZFIN MFB", type: "Microfinance Bank" },
  { value: 506, label: "MULTIVEST MFB", type: "Microfinance Bank" },
  { value: 507, label: "MUTUAL BENEFIT MFB", type: "Microfinance Bank" },
  { value: 508, label: "MUTUAL TRUST MFB", type: "Microfinance Bank" },
  { value: 509, label: "MWAGHAVUL MFB", type: "Microfinance Bank" },
  { value: 510, label: "NAGARTA MFB", type: "Microfinance Bank" },
  { value: 511, label: "NAISA MFB", type: "Microfinance Bank" },
  { value: 512, label: "NAKOWA MFB", type: "Microfinance Bank" },
  { value: 513, label: "NAMODA MFB", type: "Microfinance Bank" },
  { value: 514, label: "NASARAWA MFB", type: "Microfinance Bank" },
  { value: 515, label: "NDIOLU MFB", type: "Microfinance Bank" },
  { value: 516, label: "NDIORAH MFB", type: "Microfinance Bank" },
  { value: 517, label: "NENZIT MFB", type: "Microfinance Bank" },
  { value: 518, label: "NEPTUNE MFB", type: "Microfinance Bank" },
  { value: 519, label: "NEW DAWN MFB", type: "Microfinance Bank" },
  { value: 520, label: "NEW ERA MFB", type: "Microfinance Bank" },
  { value: 521, label: "NEW GOLDEN PASTURES MFB", type: "Microfinance Bank" },
  { value: 522, label: "NEW WOMEN'S MFB", type: "Microfinance Bank" },
  { value: 523, label: "NGBO MFB LTD", type: "Microfinance Bank" },
  { value: 524, label: "NICE MFB", type: "Microfinance Bank" },
  { value: 525, label: "NIGERIAN NAVY MFB LTD", type: "Microfinance Bank" },
  { value: 526, label: "NIGERIAN PRISONS MFB", type: "Microfinance Bank" },
  { value: 527, label: "NINGI MFB", type: "Microfinance Bank" },
  { value: 528, label: "NIRSAL MFB", type: "Microfinance Bank" },
  { value: 529, label: "NKPA MFB", type: "Microfinance Bank" },
  { value: 530, label: "NKPOLOGWU MFB", type: "Microfinance Bank" },
  { value: 531, label: "NKPOLU-UST MFB", type: "Microfinance Bank" },
  { value: 532, label: "NNOKWA MFB", type: "Microfinance Bank" },
  { value: 533, label: "NOBLE MFB", type: "Microfinance Bank" },
  { value: 534, label: "NOMASE MICROFINANCE BANK LTD", type: "Microfinance Bank" },
  { value: 535, label: "NOUN MFB", type: "Microfinance Bank" },
  { value: 536, label: "NOVEL MFB", type: "Microfinance Bank" },
  { value: 537, label: "NPF MFB", type: "Microfinance Bank" },
  { value: 538, label: "NRI MFB", type: "Microfinance Bank" },
  { value: 539, label: "NSU MFB", type: "Microfinance Bank" },
  { value: 540, label: "NSUK MFB", type: "Microfinance Bank" },
  { value: 541, label: "NSUKKA TOWNSHIP MFB", type: "Microfinance Bank" },
  { value: 542, label: "NUMO MFB", type: "Microfinance Bank" },
  { value: 543, label: "NUN MFB", type: "Microfinance Bank" },
  { value: 544, label: "NUT OKEBOLA MFB", type: "Microfinance Bank" },
  { value: 545, label: "NWANNEGADI MFB", type: "Microfinance Bank" },
  { value: 546, label: "OAKLAND MFB", type: "Microfinance Bank" },
  { value: 547, label: "OBA MFB", type: "Microfinance Bank" },
  { value: 548, label: "OBAFEMI AWOLOWO UNIVERSITY MFB", type: "Microfinance Bank" },
  { value: 549, label: "OBELEDU MFB", type: "Microfinance Bank" },
  { value: 550, label: "OBODOUKWU MFB", type: "Microfinance Bank" },
  { value: 551, label: "OBOKUN MFB", type: "Microfinance Bank" },
  { value: 552, label: "OBOLLO MFB", type: "Microfinance Bank" },
  { value: 553, label: "OCHE MFB", type: "Microfinance Bank" },
  { value: 554, label: "OCON SUCCESS MFB", type: "Microfinance Bank" },
  { value: 555, label: "OCTOPUS MFB", type: "Microfinance Bank" },
  { value: 556, label: "ODOAKPU MFB", type: "Microfinance Bank" },
  { value: 557, label: "ODU MFB", type: "Microfinance Bank" },
  { value: 558, label: "OGBERURU MFB", type: "Microfinance Bank" },
  { value: 559, label: "OGBETE MFB", type: "Microfinance Bank" },
  { value: 560, label: "OGBOEFERE MFB", type: "Microfinance Bank" },
  { value: 561, label: "OGIGE MFB", type: "Microfinance Bank" },
  { value: 562, label: "OGIYAN MFB", type: "Microfinance Bank" },
  { value: 563, label: "OHAFIA MFB", type: "Microfinance Bank" },
  { value: 564, label: "OHHA MFB", type: "Microfinance Bank" },
  { value: 565, label: "OJOKORO MFB", type: "Microfinance Bank" },
  { value: 566, label: "OKEAGBE MFB", type: "Microfinance Bank" },
  { value: 567, label: "OKENGWE MFB", type: "Microfinance Bank" },
  { value: 568, label: "OKO MFB", type: "Microfinance Bank" },
  { value: 569, label: "OKPE MFB", type: "Microfinance Bank" },
  { value: 570, label: "OKPOFE MFB", type: "Microfinance Bank" },
  { value: 571, label: "OKPOGA MFB", type: "Microfinance Bank" },
  { value: 572, label: "OKUKU MFB", type: "Microfinance Bank" },
  { value: 573, label: "OKUTA MFB", type: "Microfinance Bank" },
  { value: 574, label: "OKWO-OHA MFB", type: "Microfinance Bank" },
  { value: 575, label: "OLABISI ONABANJO UNIVERSITY MFB", type: "Microfinance Bank" },
  { value: 576, label: "OLIVE MFB", type: "Microfinance Bank" },
  { value: 577, label: "OLOWOLAGBA MFB", type: "Microfinance Bank" },
  { value: 578, label: "OLU-BASIRI MFB", type: "Microfinance Bank" },
  { value: 579, label: "OLUCHUKWU MFB", type: "Microfinance Bank" },
  { value: 580, label: "OMAK MFB", type: "Microfinance Bank" },
  { value: 581, label: "OMIYE MFB", type: "Microfinance Bank" },
  { value: 582, label: "OMUMA MFB", type: "Microfinance Bank" },
  { value: 583, label: "ONIBU-ORE MFB", type: "Microfinance Bank" },
  { value: 584, label: "ORAU'KWU MFB", type: "Microfinance Bank" },
  { value: 585, label: "OKE- ARO OREDEGBE MFB", type: "Microfinance Bank" },
  { value: 586, label: "ORILONISE MFB", type: "Microfinance Bank" },
  { value: 587, label: "ORISUN MFB", type: "Microfinance Bank" },
  { value: 588, label: "ORITA BASORUN MFB", type: "Microfinance Bank" },
  { value: 589, label: "OROKAM MFB", type: "Microfinance Bank" },
  { value: 590, label: "ORSU MFB", type: "Microfinance Bank" },
  { value: 591, label: "OSANTA MFB", type: "Microfinance Bank" },
  { value: 592, label: "OSCT MFB", type: "Microfinance Bank" },
  { value: 593, label: "OSI MFB", type: "Microfinance Bank" },
  { value: 594, label: "OSOMHE MFB", type: "Microfinance Bank" },
  { value: 595, label: "OSPOLY MFB", type: "Microfinance Bank" },
  { value: 596, label: "OSUMENYI MFB", type: "Microfinance Bank" },
  { value: 597, label: "OTUO MFB", type: "Microfinance Bank" },
  { value: 598, label: "OURS MFB", type: "Microfinance Bank" },
  { value: 599, label: "OWOTUTU MFB", type: "Microfinance Bank" },
  { value: 600, label: "OYAN MFB", type: "Microfinance Bank" },
  { value: 601, label: "PALMCOAST MFB", type: "Microfinance Bank" },
  { value: 602, label: "PALMERA MFB", type: "Microfinance Bank" },
  { value: 603, label: "PANA MFB", type: "Microfinance Bank" },
  { value: 604, label: "PARKWAY MFB", type: "Microfinance Bank" },
  { value: 605, label: "PATHFINDER MFB", type: "Microfinance Bank" },
  { value: 606, label: "PATRICKGOLD MFB", type: "Microfinance Bank" },
  { value: 607, label: "PEACE MFB", type: "Microfinance Bank" },
  { value: 608, label: "PECAN TRUST MFB", type: "Microfinance Bank" },
  { value: 609, label: "PENINSULA MFB LTD", type: "Microfinance Bank" },
  { value: 610, label: "PENNYWISE MFB", type: "Microfinance Bank" },
  { value: 611, label: "PENTECOST MFB LTD", type: "Microfinance Bank" },
  { value: 612, label: "PERSONAL TRUST MFB", type: "Microfinance Bank" },
  { value: 613, label: "PETRA MFB", type: "Microfinance Bank" },
  { value: 614, label: "PHOENIX MFB", type: "Microfinance Bank" },
  { value: 615, label: "PILLAR MFB", type: "Microfinance Bank" },
  { value: 616, label: "POLYBADAN MFB", type: "Microfinance Bank" },
  { value: 617, label: "POLYUNWANNA MFB", type: "Microfinance Bank" },
  { value: 618, label: "PREEMINENT MFB", type: "Microfinance Bank" },
  { value: 619, label: "PRESTIGE MFB", type: "Microfinance Bank" },
  { value: 620, label: "PRISCO MFB", type: "Microfinance Bank" },
  { value: 621, label: "PRISTINE DIVITIS MFB", type: "Microfinance Bank" },
  { value: 622, label: "PROJECTS MFB", type: "Microfinance Bank" },
  { value: 623, label: "PROSPECTS MFB", type: "Microfinance Bank" },
  { value: 624, label: "PROSPERITY MFB", type: "Microfinance Bank" },
  { value: 625, label: "PYRAMID MFB", type: "Microfinance Bank" },
  { value: 626, label: "QUBE MFB", type: "Microfinance Bank" },
  { value: 627, label: "QUICKFUND MFB", type: "Microfinance Bank" },
  { value: 628, label: "RAHAMA MFB", type: "Microfinance Bank" },
  { value: 629, label: "RAKIB MFB", type: "Microfinance Bank" },
  { value: 630, label: "RAMILOWO MFB", type: "Microfinance Bank" },
  { value: 631, label: "RANDALPHA", type: "Microfinance Bank" },
  { value: 632, label: "RANO MFB", type: "Microfinance Bank" },
  { value: 633, label: "REFINED BENEFITS MFB", type: "Microfinance Bank" },
  { value: 634, label: "REGENT MFB", type: "Microfinance Bank" },
  { value: 635, label: "REHOBOTH MFB", type: "Microfinance Bank" },
  { value: 636, label: "RELIANCE MFB", type: "Microfinance Bank" },
  { value: 637, label: "RENMONEY MFB", type: "Microfinance Bank" },
  { value: 638, label: "REPHIDIM MFB", type: "Microfinance Bank" },
  { value: 639, label: "RIC MFB", type: "Microfinance Bank" },
  { value: 640, label: "RICHWAY MFB LTD.", type: "Microfinance Bank" },
  { value: 641, label: "RIGO MFB", type: "Microfinance Bank" },
  { value: 642, label: "RIMA GROWTH PATHWAY MFB LTD", type: "Microfinance Bank" },
  { value: 643, label: "RIMA MFB", type: "Microfinance Bank" },
  { value: 644, label: "RIMIN GADO MFB", type: "Microfinance Bank" },
  { value: 645, label: "RIVERSIDE MFB", type: "Microfinance Bank" },
  { value: 646, label: "ROCKSHIELD MFB", type: "Microfinance Bank" },
  { value: 647, label: "ROLEZ MFB", type: "Microfinance Bank" },
  { value: 648, label: "ROYAL BLUE MFB", type: "Microfinance Bank" },
  { value: 649, label: "ROYAL EXCHANGE MFB", type: "Microfinance Bank" },
  { value: 650, label: "ROYAL LIFE KEY MFB", type: "Microfinance Bank" },
  { value: 651, label: "ROYAL MFB", type: "Microfinance Bank" },
  { value: 652, label: "RUBIES MFB", type: "Microfinance Bank" },
  { value: 653, label: "SABI MFB", type: "Microfinance Bank" },
  { value: 654, label: "SABON YELWA MFB", type: "Microfinance Bank" },
  { value: 655, label: "SABUWA MFB LTD", type: "Microfinance Bank" },
  { value: 656, label: "SAFANA MFB", type: "Microfinance Bank" },
  { value: 657, label: "SAFE HAVEN MFB", type: "Microfinance Bank" },
  { value: 658, label: "SAFEGATE MFB", type: "Microfinance Bank" },
  { value: 659, label: "SAFELINE MFB", type: "Microfinance Bank" },
  { value: 660, label: "SAGAMU MFB", type: "Microfinance Bank" },
  { value: 661, label: "SANDAMU MFB", type: "Microfinance Bank" },
  { value: 662, label: "SAPIDA MFB", type: "Microfinance Bank" },
  { value: 663, label: "SARKIN KUDU MFB LTD", type: "Microfinance Bank" },
  { value: 664, label: "SEAP MFB", type: "Microfinance Bank" },
  { value: 665, label: "SEEDVEST MFB", type: "Microfinance Bank" },
  { value: 666, label: "SENCHI MFB", type: "Microfinance Bank" },
  { value: 667, label: "Shalom MFB", type: "Microfinance Bank" },
  { value: 668, label: "SHANONO MFB", type: "Microfinance Bank" },
  { value: 669, label: "SHEPHERD TRUST MFB", type: "Microfinance Bank" },
  { value: 670, label: "SHIELD MFB", type: "Microfinance Bank" },
  { value: 671, label: "SHONGOM MFB", type: "Microfinance Bank" },
  { value: 672, label: "SINCERE MFB", type: "Microfinance Bank" },
  { value: 673, label: "SLS MFB", type: "Microfinance Bank" },
  { value: 674, label: "SMARTMICRO MFB", type: "Microfinance Bank" },
  { value: 675, label: "SNOW MFB", type: "Microfinance Bank" },
  { value: 676, label: "SOLID ALLIANZ MFB", type: "Microfinance Bank" },
  { value: 677, label: "SOLID ROCK MFB", type: "Microfinance Bank" },
  { value: 678, label: "Solid Trust MFB", type: "Microfinance Bank" },
  { value: 679, label: "SOROMAN MFB", type: "Microfinance Bank" },
  { value: 680, label: "SPARKLE MFB", type: "Microfinance Bank" },
  { value: 681, label: "SPECTRUM MFB", type: "Microfinance Bank" },
  { value: 682, label: "STANDARD MFB", type: "Microfinance Bank" },
  { value: 683, label: "STANFORD MFB", type: "Microfinance Bank" },
  { value: 684, label: "STATESIDE MFB", type: "Microfinance Bank" },
  { value: 685, label: "STELLAS MFB", type: "Microfinance Bank" },
  { value: 686, label: "STEP BY STEP MFB", type: "Microfinance Bank" },
  { value: 687, label: "STILLWATERS MFB LTD", type: "Microfinance Bank" },
  { value: 688, label: "STOCKCORP MFB", type: "Microfinance Bank" },
  { value: 689, label: "SULSPAP MFB", type: "Microfinance Bank" },
  { value: 690, label: "SUMAILA MFB", type: "Microfinance Bank" },
  { value: 691, label: "SUNBEAM MFB", type: "Microfinance Bank" },
  { value: 692, label: "SUPPORT MFB", type: "Microfinance Bank" },
  { value: 693, label: "SUPREME MFB", type: "Microfinance Bank" },
  { value: 694, label: "SURE ANCHOR MFB", type: "Microfinance Bank" },
  { value: 695, label: "TANADI MFB", type: "Microfinance Bank" },
  { value: 696, label: "TANGALE MFB", type: "Microfinance Bank" },
  { value: 697, label: "TASUED MFB", type: "Microfinance Bank" },
  { value: 698, label: "TCF MFB", type: "Microfinance Bank" },
  { value: 699, label: "TF MFB", type: "Microfinance Bank" },
  { value: 700, label: "The Millennium MFB", type: "Microfinance Bank" },
  { value: 701, label: "TOFA MFB", type: "Microfinance Bank" },
  { value: 702, label: "TOPCLASS MFB", type: "Microfinance Bank" },
  { value: 703, label: "TOP RATE MFB", type: "Microfinance Bank" },
  { value: 704, label: "TOTAL TRUST MFB", type: "Microfinance Bank" },
  { value: 705, label: "TOUCHGOLD MFB", type: "Microfinance Bank" },
  { value: 706, label: "TOWNSERVE MFB", type: "Microfinance Bank" },
  { value: 707, label: "TREASURES MFB", type: "Microfinance Bank" },
  { value: 708, label: "TRIDENT MFB", type: "Microfinance Bank" },
  { value: 709, label: "TRIPPLE A MFB", type: "Microfinance Bank" },
  { value: 710, label: "TRUST MFB", type: "Microfinance Bank" },
  { value: 711, label: "TRUSTBANC J6", type: "Microfinance Bank" },
  { value: 712, label: "TRUSTFUND MFB", type: "Microfinance Bank" },
  { value: 713, label: "TSANYAWA MFB", type: "Microfinance Bank" },
  { value: 714, label: "TUDUNWADA MFB LTD", type: "Microfinance Bank" },
  { value: 715, label: "U & C MFB", type: "Microfinance Bank" },
  { value: 716, label: "UCB MFB", type: "Microfinance Bank" },
  { value: 717, label: "UDA MFB", type: "Microfinance Bank" },
  { value: 718, label: "UDOKA MFB", type: "Microfinance Bank" },
  { value: 719, label: "UGA MFB", type: "Microfinance Bank" },
  { value: 720, label: "UGBORIMILI MFB", type: "Microfinance Bank" },
  { value: 721, label: "UHURU MFB", type: "Microfinance Bank" },
  { value: 722, label: "UKPOR MFB", type: "Microfinance Bank" },
  { value: 723, label: "UKWALA MFB", type: "Microfinance Bank" },
  { value: 724, label: "ULTIMATE MFB", type: "Microfinance Bank" },
  { value: 725, label: "UMMAH MFB", type: "Microfinance Bank" },
  { value: 726, label: "UMUAWULU MFB", type: "Microfinance Bank" },
  { value: 727, label: "UMUCHINEMERE PROCREDIT MFB", type: "Microfinance Bank" },
  { value: 728, label: "UMUCHUKWU MFB", type: "Microfinance Bank" },
  { value: 729, label: "UMUNNACHI MFB", type: "Microfinance Bank" },
  { value: 730, label: "UMUOJI MFB", type: "Microfinance Bank" },
  { value: 731, label: "UMYU Microfinance Bank Limited", type: "Microfinance Bank" },
  { value: 732, label: "UNAAB MFB", type: "Microfinance Bank" },
  { value: 733, label: "UNGOGO MFB", type: "Microfinance Bank" },
  { value: 734, label: "UNIBADAN MFB", type: "Microfinance Bank" },
  { value: 735, label: "UNICAL MFB", type: "Microfinance Bank" },
  { value: 736, label: "UNIFUND MFB", type: "Microfinance Bank" },
  { value: 737, label: "UNILAG MFB LTD", type: "Microfinance Bank" },
  { value: 738, label: "UNILORIN MFB", type: "Microfinance Bank" },
  { value: 739, label: "UNIMAID MFB", type: "Microfinance Bank" },
  { value: 740, label: "UNIUYO MFB", type: "Microfinance Bank" },
  { value: 741, label: "UNIVERSITY OF BENIN MFB", type: "Microfinance Bank" },
  { value: 742, label: "UNN MFB", type: "Microfinance Bank" },
  { value: 743, label: "UNUBI MFB", type: "Microfinance Bank" },
  { value: 744, label: "URBAN MFB", type: "Microfinance Bank" },
  { value: 745, label: "URE MFB", type: "Microfinance Bank" },
  { value: 746, label: "UTAKO MFB", type: "Microfinance Bank" },
  { value: 747, label: "UTUH MFB", type: "Microfinance Bank" },
  { value: 748, label: "UVUOMA MFB", type: "Microfinance Bank" },
  { value: 749, label: "UVURU MFB", type: "Microfinance Bank" },
  { value: 750, label: "UZONDU MFB", type: "Microfinance Bank" },
  { value: 751, label: "VCL MFB", type: "Microfinance Bank" },
  { value: 752, label: "VFD MFB LTD", type: "Microfinance Bank" },
  { value: 753, label: "VICTORY MFB", type: "Microfinance Bank" },
  { value: 754, label: "VISA MFB", type: "Microfinance Bank" },
  { value: 755, label: "VISCOUNT MFB", type: "Microfinance Bank" },
  { value: 756, label: "WAILA MFB", type: "Microfinance Bank" },
  { value: 757, label: "WELFARE MFB", type: "Microfinance Bank" },
  { value: 758, label: "WETLAND MFB", type: "Microfinance Bank" },
  { value: 759, label: "WOLIWO MFB", type: "Microfinance Bank" },
  { value: 760, label: "WOMEN DEVELOPMENT MFB", type: "Microfinance Bank" },
  { value: 761, label: "WRA MFB", type: "Microfinance Bank" },
  { value: 762, label: "WUDIL MFB", type: "Microfinance Bank" },
  { value: 763, label: "XSLNCE MFB", type: "Microfinance Bank" },
  { value: 764, label: "YABO MFB", type: "Microfinance Bank" },
  { value: 765, label: "Yankari MFB", type: "Microfinance Bank" },
  { value: 766, label: "YAURI EMIRATES MFB", type: "Microfinance Bank" },
  { value: 767, label: "YCT Microfinance Bank", type: "Microfinance Bank" },
  { value: 768, label: "YENENG MFB", type: "Microfinance Bank" },
  { value: 769, label: "YES MFB", type: "Microfinance Bank" },
  { value: 770, label: "YOBE STATE MFB", type: "Microfinance Bank" },
  { value: 771, label: "ZAFEC MFB LTD", type: "Microfinance Bank" },
  { value: 772, label: "ZAZZAU MFB", type: "Microfinance Bank" },
  { value: 773, label: "ZEFA MFB", type: "Microfinance Bank" },
  { value: 774, label: "ZIKORA MFB", type: "Microfinance Bank" },
  { value: 775, label: "ZION MFB", type: "Microfinance Bank" },
  { value: 776, label: "ZURMI MFB", type: "Microfinance Bank" },
  { value: 777, label: "ZURU MFB", type: "Microfinance Bank" },
  { value: 778, label: "KANKARA MFB", type: "Microfinance Bank" },
  { value: 779, label: "IYA-ZAKARI (MASHI) MFB", type: "Microfinance Bank" },
  { value: 780, label: "FTA FUNTUA MFB", type: "Microfinance Bank" },
  { value: 781, label: "DUTSIN-MA MFB", type: "Microfinance Bank" },
  { value: 782, label: "BATAGWARA MFB", type: "Microfinance Bank" },
  { value: 783, label: "BAURE MFB", type: "Microfinance Bank" },
  { value: 784, label: "UNITED CAPITAL MFB", type: "Microfinance Bank" },
  { value: 785, label: "NUGGETS MFB", type: "Microfinance Bank" },
  { value: 786, label: "AVE MARIA MFB", type: "Microfinance Bank" },
  { value: 787, label: "PRUDENT MFB", type: "Microfinance Bank" },
  { value: 788, label: "AWESOME MFB", type: "Microfinance Bank" },
  { value: 789, label: "WAYA MFB", type: "Microfinance Bank" },
  ];

  return listOfBanks;
};

export default Banks;
