import React from 'react';
import ChangeBaseRate from '../layout/trade/ChangeBaseRate';

const ChangeBaseRatePage = () => {
  return (
    <div>
      <ChangeBaseRate />
    </div>
  );
};

export default ChangeBaseRatePage;
