import React from "react";
import Swap from "../layout/trade/Swap";

const SwapPage = () => {
  return (
    <div>
      <Swap />
    </div>
  );
};

export default SwapPage;